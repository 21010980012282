const CryptoJS = require('crypto-js');


function encrypt (msg) {
  var b64 = CryptoJS.AES.encrypt(msg, 'IuBa76=YYjjK@1').toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
}


module.exports = {
    encrypt
}
