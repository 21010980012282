export const stateLib = {
    ACT: '1',
    NSW: '3',
    NT: '2',
    QLD: '4',
    SA: '5',
    TAS: '6',
    VIC: '8',
    WA: '7'
}

export const apiUrl = process.env.REACT_APP_API_URL + '/api'