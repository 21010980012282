import React from 'react';

// const SendInBookedPage = ({name, confirmData}) => {
    //
const SendInBookedPage = (props) => {
    const {customer_name, order, url, centrePhone} = props.appProps.data
    return (
        <div className="text-center xl:max-w-4xl mx-auto px-4 pb-20">
            <h2 className="main-title pt-24 pb-8">Your Send-in Repair Service is Booked</h2>

            <p className="text-left text-md sm:text-lg">
                <br/>
                Dear {customer_name},<br/>

                Thank you for requesting Send-in repair service for your realme handset.<br/>

                Your Reference No. is: {order}<br/>

                Please <a href={url} target="_blank" className="text-blue-500 hover:underline" rel="noopener noreferrer">download</a> and print the consignment note to send your handset and proof of purchase to us.<br/>

                For safety purpose, we would recommend you to wrap the handset with bubble wraps or magazine to avoid physical damage during transport.<br/>

                Upon completion, please visit your local Australia Post to send off this parcel.<br/><br/>


                Goods presented for repair may be replaced by refurbished goods of the same type rather than being repaired. Refurbished parts may be used to repair the goods.<br/><br/>


                Please be aware that if handset is assessed to have physical damage and/or liquid damage, handset will not be covered under Warranty Service. As such, a $12 freight cost will be added to your Quoted repair. If the device is assessed to be beyond economical repair, realme will provide you a recommendation of purchasing a refurbished device. This resolution is recommended for the purpose to avoid potential post-repair malfunctions from the Out-of-Warranty damage, such as liquid damage, severe physical damage, etc.<br/><br/>


                If the handset is assessed to be Out-of-Warranty, realme will send an email quote to customer which is provided by the customer. This email acts as a written notice for collection of handsets within 3 months. Under Uncollected Goods Act 1995, realme has the right to dispose of any uncollected goods (handsets) 3 months from the date of the written notification. Thereupon, realme will dispose of the uncollected handset at realme Service Centre. It is customer's responsibility to ensure all personal information (name, contact phone number, email, etc.) provided to realme are correct. realme will not be held responsible for any incorrect email provided by customer if the email notification cannot be delivered due to an incorrect email address, uncollected handsets will still be disposed of after 3 months.<br/><br/>


                Prior to sending:<br/>
                1. Please back-up all your personal data.<br/>
                2. Please remove any SIM cards and/or Micro SD Cards.<br/>
                3. Please package your handset safely, for example with bubble wrap. We do not take responsibility of any damage during transport.<br/><br/>


                If you are experiencing difficulty in sending the device, please contact <a href={`tel:${centrePhone}`}  className="text-blue-500 hover:underline">{centrePhone}</a> for assistance.<br/><br/>


                Kind Regards,<br/>

                realme Customer Support Team<br/>
            </p>
        </div>
    )
}

export default SendInBookedPage;
