import React from 'react';

const Modal = (props) => {
  const { content, isOpen, setIsOpen } = props;

  if (isOpen) {
    return (
      <div>
        <div
          className="modal-overlay"
          onClick={() => {
            setIsOpen();
          }}
        ></div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="modal-content bg-white rounded-lg shadow-lg w-full lg:max-w-3xl py-8 px-4 mt-64 sm:mt-0"
        >
          <div className="text-right">
            <i
              className="fal fa-times text-2xl px-6 cursor-pointer"
              onClick={() => {
                setIsOpen();
              }}
            />
          </div>
          {content()}
        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
