import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';

const CustomerInfo = (props) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const defaultFormStyle =
    'selector border-gray-400 form-control md:w-96 w-64 my-4 h-10 bg-white';

  const onSubmit = (data) => {
    props.appProps.setData({
      ...props.appProps.data,
      customer_name: data.customer_name.trim(),
      customer_number: data.customer_number.trim(),
      customer_email: data.customer_email.trim(),
    });
    props.history.push('/walkin-service-booking/4');
  };

  useEffect(() => {
    if (props.appProps.data.customer_email) {
      const {
        customer_name,
        customer_email,
        customer_number,
      } = props.appProps.data;
      setValue('customer_name', customer_name);
      setValue('customer_number', customer_number);
      setValue('customer_email', customer_email);
    }
  }, [setValue, props.appProps.data]);

  return (
    <div className="text-center">
      <h1 className="main-title pt-24 pb-8">Customer Details</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex xl:max-w-6xl mx-auto pb-6 relative flex-col items-center"
      >
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true })}
          placeholder="Name"
          name="customer_name"
          maxLength="60"
        />
        {errors.customer_name && (
          <p className="error">Customer name is required</p>
        )}
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true, pattern: /^04\d{8}$/ })}
          placeholder="Contact Number"
          name="customer_number"
          maxLength="10"
        />
        {errors.customer_number &&
          errors.customer_number.type === 'required' && (
            <p className="error">Customer contact number is required</p>
          )}
        {errors.customer_number &&
          errors.customer_number.type === 'pattern' && (
            <p className="error">
              Customer contact number should start from 04
            </p>
          )}
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
          placeholder="Email"
          name="customer_email"
        />
        {errors.customer_email && errors.customer_email.type === 'required' && (
          <p className="error">Customer email is required</p>
        )}
        {errors.customer_email && errors.customer_email.type === 'pattern' && (
          <p className="error">Invalid email</p>
        )}
        <div>
          <Link
            to="/walkin-service-booking/2"
            className="btn my-8 cursor-pointer"
          >
            BACK
          </Link>
          <input
            type="submit"
            className="btn my-8 cursor-pointer ml-12"
            value="NEXT"
          />
        </div>
      </form>
    </div>
  );
};

export default withRouter(CustomerInfo);
