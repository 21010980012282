import React, { useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import { apiUrl } from '../../config';
const moment = require('moment');
const { encrypt } = require('../../utils/encypted');
const { getCentreId } = require('../../utils/getStateId');

const Summary = (props) => {
  const { register, errors, handleSubmit } = useForm();
  const {
    modelName,
    color,
    colorName,
    imei,
    purchaseDate,
    fault_option,
    fault_description,
    customer_name,
    customer_number,
    customer_email,
    customer_address_1,
    customer_address_2,
    customer_suburb,
    customer_state,
    customer_postcode,
  } = props.appProps.data;
  const [isVerify, setIsVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verifyWarning, setVerifyWarning] = useState(false);

  const onSubmit = async (data) => {
    if (isVerify) {
      try {
        const payload = {
          stateId: encrypt(getCentreId(customer_state)),
          imei: encrypt(imei),
          colourId: encrypt(color),
          purchaseDate: encrypt(moment(purchaseDate).format('YYYY-MM-DD')),
          name: encrypt(customer_name),
          phone: encrypt(customer_number),
          email: encrypt(customer_email),
          address1: encrypt(customer_address_1),
          address2:
            customer_address_2 === ''
              ? customer_address_2
              : encrypt(customer_address_2),
          suburb: encrypt(customer_suburb),
          customer_state: encrypt(customer_state),
          postcode: encrypt(customer_postcode),
          faultDetail: encrypt(fault_option.join(',')),
          faultDesc: encrypt(fault_description),
        };
        setIsLoading(true);
        const result = await axios.post(`${apiUrl}/setSendIn`, payload);
        setIsLoading(false);
        if (result.data.code === '0') {
          const {
            order,
            centreName,
            centrePhone,
            centreAddress,
            url,
          } = result.data.body;
          props.appProps.setData({
            ...props.appProps.data,
            order,
            url,
            centreName,
            centrePhone,
            centreAddress,
          });
          props.history.push('/sendin-service-booking/confirmed');
        } else {
          throw new Error('Internal error');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setVerifyWarning(true);
    }
  };

  const verifyCallback = useCallback((response) => {
    if (response) {
      setIsVerify(true);
    }
  }, []);

  return (
    <div className="text-center">
      <h1 className="main-title pt-24 pb-8">Send-in Repair Details</h1>
      <div className="flex flex-col sm:flex-row py-12 text-center xl:max-w-6xl mx-auto">
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Product Model
          </h2>
          <p>{modelName}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Product Colour
          </h2>
          <p>{colorName}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            IMEI
          </h2>
          <p>{imei}</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row border-t border-b py-12 text-center xl:max-w-6xl mx-auto">
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Purchase Date
          </h2>
          <p>{purchaseDate && moment(purchaseDate).format('DD/MM/YYYY')}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Fault Details
          </h2>
          <p>{fault_option && fault_option.join(', ')}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Description
          </h2>
          <p>{fault_description}</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row border-t py-12 text-center xl:max-w-6xl mx-auto">
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Customer Name
          </h2>
          <p>{customer_name}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Contact Number
          </h2>
          <p>{customer_number}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Email
          </h2>
          <p>{customer_email}</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row border-t py-12 text-center xl:max-w-6xl mx-auto">
        <div className="flex flex-col w-full sm:w-1/2 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Address 1
          </h2>
          <p>{customer_address_1}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/2 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Address 2
          </h2>
          <p>{customer_address_2}</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row border-t py-12 text-center xl:max-w-6xl mx-auto">
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Suburb/Town
          </h2>
          <p>{customer_suburb}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            State
          </h2>
          <p>{customer_state}</p>
        </div>
        <div className="flex flex-col w-full sm:w-1/3 pb-8 sm:pb-0">
          <h2 className="text-lg font-semibold mb-2 sm:mb-3 tracking-wider">
            Postcode
          </h2>
          <p>{customer_postcode}</p>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="xl:max-w-6xl mx-auto mt-8"
      >
        <p className="pt-2 pb-1 font-semibold">
          Please remember to back-up your data as per warranty policy, all data
          will be wiped off.
        </p>
        <label className="block text-gray-500 font-bold cursor-pointer px-2 mt-2 text-left">
          <input
            className="mr-2 leading-tight"
            type="checkbox"
            ref={register({ required: true })}
            name="accept_1"
          />
          <span
            className={errors.accept_1 ? 'text-sm text-red-600' : 'text-sm'}
          >
            It is required to provide a copy of proof of purchase receipt for
            warranty services. Please contact us if you are unable to provide
            proof of purchase.
          </span>
        </label>
        <label className="block text-gray-500 font-bold cursor-pointer px-2 text-left">
          <input
            className="mr-2 leading-tight"
            type="checkbox"
            ref={register({ required: true })}
            name="accept_2"
          />
          <span
            className={errors.accept_2 ? 'text-sm text-red-600' : 'text-sm'}
          >
            I have read and accept the Warranty{' '}
            <a
              href="https://www.realme.com/au/legal/warranty-terms"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Terms and Conditions
            </a>
          </span>
        </label>
        <div
          style={{ width: '304px' }}
          className={
            verifyWarning
              ? 'mx-auto mt-4 border border-red-400'
              : 'mx-auto mt-4'
          }
        >
          <Recaptcha
            sitekey="6LcXQeMUAAAAAJa-0Ga5pIzlj9TteKysNV_nC2id"
            render="explicit"
            verifyCallback={verifyCallback}
            className="cursor-pointer"
          />
        </div>
        <div>
          <Link
            to="/sendin-service-booking/3"
            className="btn my-8 cursor-pointer"
          >
            BACK
          </Link>
          <button
            type="submit"
            className="btn my-8 cursor-pointer ml-12 uppercase"
            disabled={isLoading}
          >
            <span>
              {isLoading && (
                <i className="fad fa-spinner fa-spin fa-spinner-third mr-2 text-md"></i>
              )}
              next
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(Summary);
