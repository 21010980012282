import React, {useState} from 'react';
import Header from './components/header';
import Footer from './components/footer';
import './styles/app.css';
import Routes from "./Routes";

function App(props) {
  const [hasSession, setHasSession] = useState(false)
  const [data, setData] = useState({})
  const headerNav = {
    "Shop": "https://shop.realme.net.au/",
    "Products": "https://shop.realme.net.au/collections/phones",
    "Accessories": "https://shop.realme.net.au/collections/accessories",
    "Compare": "https://compare.realme.net.au/",
    "Brand": "https://www.realme.com/au/brand",
    "Support": "https://www.realme.com/au/support",
    "Promotions": "https://promotions.realme.net.au/"
  }

  return (
    <div className="App">
      <Header appProps={headerNav}/>
      <div className="pt-12 bg-gray-100">
        <Routes appProps={{ hasSession, setHasSession, data, setData}} />
      </div> 
      <Footer />
    </div>
  );
}

export default App;
