import React from 'react';
import Twitter from '../static/twitter-brands.svg';
import FaceBook from '../static/facebook-square-brands.svg';
import Ins from '../static/instagram-brands.svg';

const Footer = () => {
  return (
    <footer className="pt-12">
      <div className="px-4 xl:max-w-6xl mx-auto">
        <ul className="flex mb-4 md:flex-row flex-col">
          <li className="md:w-1/5">
            <h3 className="font-medium tracking-wide py-4">Recommend</h3>
            <ul>
              <li className="py-1">
                <a
                  href="https://shop.realme.net.au"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  Shop
                </a>
              </li>
              <li className="py-1">
                <a
                  href="https://shop.realme.net.au/collections/phones"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  Phones
                </a>
              </li>
              <li className="py-1">
                <a
                  href="https://shop.realme.net.au/collections/accessories"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  Accessories
                </a>
              </li>
              <li className="py-1">
                <a
                  href="https://compare.realme.net.au"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  Compare Models
                </a>
              </li>
            </ul>
          </li>
          <li className="md:w-1/5">
            <h3 className="font-medium tracking-wide py-4">Support</h3>
            <ul>
              <li className="py-1">
                <a
                  href="https://www.realme.com/au/support/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  FAQ
                </a>
              </li>
              <li className="py-1">
                <a
                  href="https://www.realme.com/au/support/troubleshooting"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  Troubleshooting
                </a>
              </li>
            </ul>
          </li>
          <li className="md:w-1/5">
            <h3 className="font-medium tracking-wide py-4">About realme</h3>
            <ul>
              <li className="py-1">
                <a
                  href="https://www.realme.com/au/brand"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  Our Brand
                </a>
              </li>
            </ul>
          </li>
          <li className="md:w-1/5">
            <h3 className="font-medium tracking-wide py-4">Contact realme</h3>
            <ul>
              <li className="py-1">
                <a
                  href="mailto:service.au@realme.com"
                  className="hover:text-primary-500 text-xs xl:text-sm"
                >
                  service.au@realme.com
                </a>
              </li>
            </ul>
          </li>
          <li className="md:w-1/5">
            <ul className="py-3">
              <li className="py-2 text-center">
                <a
                  href="tel: 1800 942 952"
                  className="hover:text-primary-500 text-sm"
                >
                  1800 942 952
                </a>
              </li>
              <li className="py-2 text-xs text-center">
                09:00 - 18:00 (AET), MON - FRI
              </li>
              <li className="py-2">
                <ul className="flex justify-around w-24 mx-auto">
                  <li>
                    <a
                      href="https://www.facebook.com/realmeAustralia"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        className="h-6"
                        src={FaceBook}
                        alt="social-media-fb"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/realmeAustralia"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        className="h-6"
                        src={Twitter}
                        alt="social-media-twitter"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/realmeaustralia"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img className="h-6" src={Ins} alt="social-media-ins" />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div className="border-t border-gray-400 flex md:flex-row flex-col justify-center md:justify-between pt-6 pb-10 text-xs text-gray-800">
          <p className="text-center">
            &copy;{new Date().getFullYear()} realme. All Rights Reserved.
          </p>
          <p className="text-center">
            <a
              href="https://www.realme.com/au/legal/user-agreement"
              rel="noopener noreferrer"
              target="_blank"
              className="px-2 border-r"
            >
              User Agreement
            </a>
            <a
              href="https://www.realme.com/au/legal/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
              className="px-2 border-r"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.realme.com/au/legal/terms-sales"
              rel="noopener noreferrer"
              target="_blank"
              className="px-2 border-r"
            >
              Terms of Sales
            </a>
            <a
              href="https://www.realme.com/au/legal/warranty-terms"
              rel="noopener noreferrer"
              target="_blank"
              className="px-2"
            >
              Warranty Terms
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
