import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeroImg from '../static/store-banner.png'

const defaultFormStyle = "selector border-gray-400 form-control md:w-96 w-64 my-4 h-10 bg-white cursor-pointer"
class Home extends Component {
	constructor() {
		super();
		this.state = {
			selected: 'NSW'
		}
	}

	componentDidMount(){
		document.title = this.props.title
	}

	stateOnChange(addr) {
		this.setState({
			selected: addr
		})
	}

	render() {
		// const { Option } = Select;
		const nsw_info = {
			'name': 'Macquarie Park OPPO Service Centre',
			'address': 'Level 3, 5 Talavera Rd, Macquarie Park NSW 2113',
			'time': '09:00 - 12:30 13:30 - 17:30',
			'tel': '02 8355 5184'
		}
		const vic_info = {
			'name': 'Collins Street Melbourne OPPO Service Centre',
			'address': 'Tenant 2&3, Level 11 South Tower 459 Collins Street Melbourne VIC 3000',
			'time': '09:00 - 12:00 12:30 - 17:30',
			'tel': '03 9982 9778'
		}
		return (
			<React.Fragment>
				<div className="hero bg-cover h-64 w-screen bg-center" style={{ backgroundImage: `url(${HeroImg})` }}>
					<div className="hero__content text-center">
						<h2 className="heading-secondary--hero text-white text-4xl font-bold tracking-wide text-center pt-24">
							Find Service Center
						</h2>
					</div>
				</div>
				<div className="region-result md:py-16 py-6">
					<div className="region-result__header py-4 text-center">
						<p className="paragraph--result text-md px-2">
							realme Service Centres provide our users with genuine, convenient and professional services, including troubleshooting, software upgrading, repairing and more.
						</p>
					</div>
					<div className="region-result__content">
						<div className="region-result__content__booking flex max-w-md mx-auto flex-col md:flex-row items-center">
							<div className="md:w-1/2 walk-in py-4">
								<span className="icon-walkin"></span><Link to="/walkin-service-booking" className="inline-block ml-4 hover:text-primary-500"><h3 className="heading-tertiary--walkin font-semibold">Book Walk-in Service</h3></Link>
							</div>
							<div className="md:w-1/2 send-in md:ml-6 ml-0">
								<span className="icon-sendin"></span><Link to="/sendin-service-booking" className="inline-block ml-4 hover:text-primary-500"><h3 className="heading-tertiary--sendin font-semibold">Book Send-in Service</h3></Link>
							</div>
						</div>
						<div className="relative md:w-96 w-64 mx-auto">
							<select defaultValue={this.state.selected} className={defaultFormStyle} onChange={(e) => {this.stateOnChange(e.target.value)}}>
								<option value="NSW">NSW</option>
								<option value="VIC">VIC</option>
							</select>
							<div className="selector-icon">
								<i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
							</div>
						</div>
						<div className="region-result__content__info text-center">
							<h3 className="heading-tertiary--sendin py-2 font-bold">Name</h3>
							<p className="paragraph--result py-3 font-semibold">
								{this.state.selected === 'NSW' ? nsw_info.name : vic_info.name}
							</p>
							<h3 className="heading-tertiary--sendin py-2 font-bold">Address</h3>
							<p className="paragraph--address py-3 font-semibold">
								{this.state.selected === 'NSW' ? nsw_info.address : vic_info.address}
							</p>
							<h3 className="heading-tertiary--sendin py-2 font-bold">Business hours</h3>
							<p className="paragraph--result py-3 font-semibold">
								{this.state.selected === 'NSW' ? nsw_info.time : vic_info.time}
							</p>
							<h3 className="heading-tertiary--sendin py-2 font-bold">Contact</h3>
							<p className="paragraph--result py-3 font-semibold">
								{this.state.selected === 'NSW' ? nsw_info.tel : vic_info.tel}
							</p>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Home;

