import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function AppliedRoute({ component: C, appProps, ...rest }) {
  let hasSession = appProps.hasSession;

  useEffect(() => {
    if (!appProps.hasSession) {
      appProps.setHasSession(true);
    }
  }, [appProps]);

  if (hasSession) {
    return (
      <Route {...rest} render={(props) => <C {...props} {...appProps} />} />
    );
  } else {
    return <Redirect to={`${appProps.url}/1`} />;
  }
}
