import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../components/Modal';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../../config';
const { encrypt } = require('../../utils/encypted');

const InputIMEI = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    unregister,
    getValues,
  } = useForm();
  const [goodSearch, setGoodSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imeiSearch, setImeiSearch] = useState(false);
  const [modelArray, setModelArray] = useState([]);
  const [channelArray, setChannelArray] = useState([]);
  const [colorArray, setColorArray] = useState([]);
  const [modelData, setModelData] = useState(null);
  const [imgLink, setImgLink] = useState(
    'https://cdn.shopify.com/s/files/1/0240/8470/9453/files/realme-xt-blue_medium.png'
  );
  const defaultFormStyle =
    'selector border-gray-400 form-control w-64 my-4 h-10 bg-white cursor-pointer';
  const disabledFormStyle =
    'selector border-gray-400 form-control w-64 my-4 h-10 bg-white cursor-not-allowed bg-gray-200';

  const [selectedDate, setselectedDate] = useState(null);

  const onSelectModel = async (modelId) => {
    setValue('channel', '');
    setValue('color', '');
    setValue('purchaseDate', '');
    setselectedDate(null);
    const modelName = modelArray.filter((ele) => ele.id + '' === modelId);
    try {
      const payload = {
        name: modelName[0].name,
      };
      //   const result = await axios.post(`${apiUrl}/getColor`, payload);
      const result = await axios.post(`${apiUrl}/getChannel`, payload);
      if (result.data.code === '0') {
        // setColorArray(result.data.body);
        setChannelArray(result.data.body);
      } else {
        throw new Error(result.data.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (date) => {
    setValue('purchaseDate', date);
    setselectedDate(date);
  };

  const onSubmitImei = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        const result = await axios.get(
          `${apiUrl}/getImeiModel?imei=${encrypt(data.imei)}`
        );
        if (result.data.code === '0') {
          // Get the model
          const { model, colour, url } = result.data.body[0];
          setIsLoading(false);
          setGoodSearch(true);
          setImgLink(url);
          setValue('model', model);
          setModelData(result.data.body[0]);
          setValue('color', colour);
          setImeiSearch(true);
          register({ name: 'purchaseDate', value: '' }, { required: true });
        } else {
          // cannot get the model
          setIsLoading(false);
          setGoodSearch(false);
          if (modelArray.length !== 0) {
            setImeiSearch(true);
            register({ name: 'purchaseDate', value: '' }, { required: true });
          } else {
            setIsLoading(true);
            try {
              const result = await axios.get(`${apiUrl}/getModel`);
              setIsLoading(false);
              if (result.data.code === '0') {
                setModelArray(result.data.body);
                setImeiSearch(true);
                register(
                  { name: 'purchaseDate', value: '' },
                  { required: true }
                );
              } else {
                throw new Error(result.data.msg);
              }
            } catch (error) {
              setIsLoading(false);
              console.log(error);
            }
          }
        }
      } catch (error) {
        setIsLoading(false);
        setGoodSearch(false);
        console.log(error);
      }
    },
    [modelArray, register, setValue]
  );

  const onSubmitModel = (data) => {
    let modelName = '';
    let colorName = '';
    if (goodSearch) {
      modelName = modelData.model;
      colorName = modelData.colour;
    } else {
      modelName = modelArray.filter((ele) => ele.id + '' === data.model)[0]
        .name;
      colorName = colorArray.filter(
        (ele) => ele.colourId + '' === data.color
      )[0].name;
    }
    props.appProps.setData({
      ...props.appProps.data,
      imei: data.imei,
      model: data.model,
      modelName,
      color: data.color,
      colorName,
      purchaseDate: data.purchaseDate,
    });
    props.history.push('/walkin-service-booking/3');
  };

  const onSelectChannel = async (channelValue) => {
    setValue('color', '');
    setValue('purchaseDate', '');
    setselectedDate(null);
    try {
      const modelName = modelArray.filter(
        (ele) => ele.id + '' === getValues()['model']
      );
      const payload = {
        name: modelName[0].name,
        channel: channelValue,
      };
      const result = await axios.post(`${apiUrl}/getColor`, payload);
      if (result.data.code === '0') {
        setColorArray(result.data.body);
      } else {
        throw new Error(result.data.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectColor = (colorId) => {
    const result = colorArray.filter((ele) => ele.colourId + '' === colorId);
    setImgLink(result[0].url);
  };

  const closeModal = useCallback(() => {
    unregister('purchaseDate');
    setImeiSearch(false);
    setselectedDate(null);
    setValue('color', '');
    setValue('purchaseDate', '');
  }, [unregister, setValue]);

  useEffect(() => {
    if (props.appProps.data.imei) {
      setValue('imei', props.appProps.data.imei);
    }
  }, [setValue, props.appProps.data.imei]);

  return (
    <div className="text-center">
      <h1 className="main-title pt-24 pb-8">Handset Information</h1>
      <form
        onSubmit={handleSubmit(onSubmitImei)}
        className="flex xl:max-w-6xl mx-auto justify-center pb-6 relative flex-col"
      >
        <div className="flex relative mx-auto">
          <input
            type="text"
            placeholder="IMEI number"
            name="imei"
            className="search border-gray-400 md:w-96 w-48 my-4 h-10"
            ref={register({ required: true, minLength: 15, pattern: /^\d+$/i })}
            maxLength="15"
          />
          <div>
            <button
              type="submit"
              className="h-10 text-white bg-primary-500 my-4 cursor-pointer px-4 ml-3 rounded hover:bg-primary-600 font-semibold focus:outline-none focus:shadow-outline uppercase"
            >
              <span>
                {isLoading && (
                  <i className="fad fa-spinner fa-spin fa-spinner-third px-1"></i>
                )}
                search
              </span>
            </button>
          </div>
        </div>
        {errors.imei && errors.imei.type === 'required' && (
          <p className="error block mx-auto text-center py-2">
            IMEI number is required
          </p>
        )}
        {errors.imei && errors.imei.type === 'pattern' && (
          <p className="error block mx-auto text-center py-2">
            IMEI contains all numbers
          </p>
        )}
        {errors.imei && errors.imei.type === 'minLength' && (
          <p className="error block mx-auto text-center py-2">
            IMEI number is 15 length
          </p>
        )}
        {errors.imei && errors.imei.type === 'not_found' && (
          <p className="error block mx-auto text-center py-2">
            IMEI number not found
          </p>
        )}
      </form>
      <Modal
        isOpen={imeiSearch}
        setIsOpen={closeModal}
        content={() => (
          <div>
            {!goodSearch && (
              <div>
                <p className="text-red-600 text-md md:text-lg py-8">
                  * IMEI number not found. Please select your device details
                  below.
                </p>
              </div>
            )}
            <div className="flex flex-col sm:flex-row justify-around items-center">
              <div>
                <img src={imgLink} alt="phone-img" />
              </div>
              <form
                className="flex flex-col"
                onSubmit={handleSubmit(onSubmitModel)}
              >
                <div className="relative">
                  <select
                    defaultValue={goodSearch ? modelData.modelId : ''}
                    name="model"
                    className={
                      goodSearch ? disabledFormStyle : defaultFormStyle
                    }
                    ref={register({ required: true })}
                    disabled={goodSearch ? true : false}
                    onChange={(e) => {
                      onSelectModel(e.target.value);
                    }}
                  >
                    <option value="">Please select model</option>
                    {!goodSearch &&
                      modelArray.map((ele, index) => (
                        <option value={ele.id} key={index}>
                          {ele.name}
                        </option>
                      ))}
                    {goodSearch && (
                      <option value={modelData.modelId} key={modelData.modelId}>
                        {modelData.model}
                      </option>
                    )}
                  </select>
                  <div
                    style={{ position: 'absolute', top: '24px', right: '8px' }}
                  >
                    <i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
                  </div>
                </div>
                {errors.model && <p className="error">Model is required</p>}
                {/* add channel selector */}
                <div className="relative">
                  <select
                    defaultValue={goodSearch ? modelData.channel : ''}
                    name="channel"
                    className={
                      watch('model') && !goodSearch
                        ? defaultFormStyle
                        : disabledFormStyle
                    }
                    ref={register({ required: true })}
                    disabled={watch('model') && !goodSearch ? false : true}
                    onChange={(e) => {
                      onSelectChannel(e.target.value);
                    }}
                  >
                    <option value="">Please select channel</option>
                    {!goodSearch &&
                      channelArray.map((ele, index) => (
                        <option key={index} value={ele}>
                          {ele}
                        </option>
                      ))}
                    {goodSearch && (
                      <option value={modelData.channel}>
                        {modelData.channel}
                      </option>
                    )}
                  </select>
                  <div
                    style={{ position: 'absolute', top: '24px', right: '8px' }}
                  >
                    <i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
                  </div>
                  {errors.channel && (
                    <p className="error">Channel is required</p>
                  )}
                </div>
                {/* end of channel selector */}
                <div className="relative">
                  <select
                    defaultValue={goodSearch ? modelData.colourId : ''}
                    name="color"
                    className={
                      watch('channel') && !goodSearch
                        ? defaultFormStyle
                        : disabledFormStyle
                    }
                    ref={register({ required: true })}
                    disabled={watch('channel') && !goodSearch ? false : true}
                    onChange={(e) => {
                      onSelectColor(e.target.value);
                    }}
                  >
                    <option value="">Please select colour</option>
                    {!goodSearch &&
                      colorArray.map((ele, index) => (
                        <option key={index} value={ele.colourId}>
                          {ele.name}
                        </option>
                      ))}
                    {goodSearch && (
                      <option value={modelData.colourId}>
                        {modelData.colour}
                      </option>
                    )}
                  </select>
                  <div
                    style={{ position: 'absolute', top: '24px', right: '8px' }}
                  >
                    <i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
                  </div>
                  {errors.color && <p className="error">Colour is required</p>}
                </div>
                <DatePicker
                  className={
                    !watch('color') ? disabledFormStyle : defaultFormStyle
                  }
                  dateFormat="dd/MM/yyyy"
                  selected={selectedDate}
                  onChange={handleChange}
                  placeholderText="Please select date"
                  disabled={!watch('color')}
                  maxDate={new Date()}
                  showYearDropdown
                  yearDropdownItemNumber={10}
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                />
                {errors.purchaseDate && (
                  <p className="error">Purchase date is required</p>
                )}
                <input
                  type="submit"
                  className="btn my-8 cursor-pointer"
                  value="NEXT"
                />
              </form>
            </div>
          </div>
        )}
      />
      <div className="xl:max-w-6xl mx-auto md:pb-20 px-4 pb-10 border-t">
        <h3 className="text-2xl py-8">Kind Reminder:</h3>
        <div className="text-left max-w-md mx-auto">
          <p>To find out your IMEI:</p>
          <p>1. Dial *#06# on the keypad to retrieve, or</p>
          <p>2. Check the bottom of product packaging box, or</p>
          <p>
            3. Go to [Settings] &gt; [About Phone] &gt; [Status] &gt; [IMEI]
          </p>
          <p>
            4. If you are unable to find out your IMEI, you can enter 15 zeros
            (000000000000000)
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(InputIMEI);
