import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import axios from 'axios';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { apiUrl } from '../config';
const { encrypt } = require('../utils/encypted');

const ReturnClaim = (props) => {
  const { register, handleSubmit, errors, watch, setValue } = useForm();
  // status 0->initial status, 1->loading status, 2->successful status, 3->failed status
  const [status, setStatus] = useState(0);
  const [verifyToken, setVerifyToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      const token = await props.googleReCaptchaProps.executeRecaptcha(
        'returnClaim'
      );
      setVerifyToken(token);
    };
    getToken();
  }, [props.googleReCaptchaProps]);

  const defaultFormStyle =
    'selector border-gray-400 form-control sm:w-96 w-64 mt-2 mb-4 h-10 bg-white';
  const errorFormStyle =
    'selector border-red-500 form-control sm:w-96 w-64 mt-2 mb-4 h-10 bg-white';

  const onSubmit = async (data) => {
    setStatus(1);
    const payload = {
      return_info: {
        order_number: encrypt(data.order_number),
        name: encrypt(data.name),
        phone: encrypt(data.phone),
        email: encrypt(data.email),
        address: encrypt(data.address),
        reason: encrypt(data.reason),
        description: encrypt(data.description),
        condition: encrypt(data.condition),
      },
      recaptcha: verifyToken,
    };
    try {
      await axios.post(`${apiUrl}/set-return-claim`, payload);
      // if success, clear add data
      [
        'order_number',
        'name',
        'phone',
        'email',
        'address',
        'reason',
        'description',
        'condition',
      ].forEach((field) => {
        setValue(field, '');
      });
      setStatus(2);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setStatus(3);
        }
      }
    }
  };

  return (
    <div id="return-claim">
      <div className="text-center">
        <h1 className="main-title pt-24">Return Claim</h1>
        <p className="pb-8 px-2">
          Please fill in and submit the form below. Your claim will be reviewed
          and replied by email.
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex xl:max-w-6xl mx-auto pb-6 relative flex-col items-center"
        >
          <div className="relative">
            <p className="text-left text-lg">
              Order number
              <Tippy
                popperOptions={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -9],
                      },
                    },
                  ],
                }}
                content={
                  <span className="relative bottom-0">
                    Usually found on your receipt or tax invoice
                  </span>
                }
              >
                <span className="cursor-pointer text-gray-500 hover:text-gray-800 p-3">
                  <i className="far fa-question-circle fill-current text-lg"></i>
                </span>
              </Tippy>
            </p>
            <input
              className={defaultFormStyle}
              type="text"
              ref={register({ required: true })}
              name="order_number"
              maxLength="60"
            />
            {errors.order_number && (
              <p className="error">Order number is required</p>
            )}
          </div>
          <div className="relative">
            <p className="text-left text-lg">Name</p>
            <input
              className={errors.name ? errorFormStyle : defaultFormStyle}
              type="text"
              ref={register({ required: true })}
              name="name"
              maxLength="60"
            />
            {errors.name && <p className="error">Name is required</p>}
          </div>
          <div className="relative">
            <p className="text-left text-lg">Contact number</p>
            <input
              className={errors.phone ? errorFormStyle : defaultFormStyle}
              type="text"
              ref={register({ required: true, pattern: /^04\d{8}$/ })}
              name="phone"
              maxLength="10"
            />
            {errors.phone && errors.phone.type === 'required' && (
              <p className="error">Customer contact number is required</p>
            )}
            {errors.phone && errors.phone.type === 'pattern' && (
              <p className="error">
                Customer contact number should start from 04
              </p>
            )}
          </div>
          <div className="relative">
            <p className="text-left text-lg">Email</p>
            <input
              className={errors.email ? errorFormStyle : defaultFormStyle}
              type="text"
              ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
              name="email"
            />
            {errors.email && errors.email.type === 'required' && (
              <p className="error">Customer email is required</p>
            )}
            {errors.email && errors.email.type === 'pattern' && (
              <p className="error">Invalid email</p>
            )}
          </div>
          <div className="relative">
            <p className="text-left text-lg">Address</p>
            <input
              className={errors.address ? errorFormStyle : defaultFormStyle}
              type="text"
              ref={register({ required: true })}
              name="address"
            />
            {errors.address && <p className="error">Address is required</p>}
          </div>
          <div className="relative">
            <p className="text-left text-lg">Return reason</p>
            <div className="relative">
              <select
                name="reason"
                className={
                  watch('reason')
                    ? defaultFormStyle
                    : errors.reason
                    ? `${errorFormStyle} text-gray-500`
                    : `${defaultFormStyle} text-gray-500`
                }
                ref={register({ required: true })}
                defaultValue=""
              >
                <option value="" disabled>
                  Please select return reason
                </option>
                <option value="Wrong product purchased">
                  Wrong product purchased
                </option>
                <option value="Faulty Product">Faulty Product</option>
              </select>
              <div
                style={{ position: 'absolute', top: '18px', right: '8px' }}
                className={watch('reason') ? '' : 'text-gray-500'}
              >
                <i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
              </div>
              {errors.reason && (
                <p className="error">Return reason is required</p>
              )}
            </div>
          </div>
          <div className="relative">
            <p className="text-left text-lg ml-4">Return Description</p>
            <textarea
              name="description"
              ref={register({ required: true })}
              className={
                errors.description
                  ? 'md:w-96 w-64 h-40 mt-2 mb-4 border border-red-400 focus:outline-none focus:shadow-outline text-md p-2 mx-4'
                  : 'md:w-96 w-64 h-40 mt-2 mb-4 border focus:outline-none focus:shadow-outline text-md p-2 mx-4'
              }
            />
            {errors.description && (
              <p className="error">Product description is required</p>
            )}
          </div>
          <div className="relative">
            <p className="text-left text-lg">Product condition</p>
            <div className="relative">
              <select
                name="condition"
                className={
                  watch('condition')
                    ? defaultFormStyle
                    : errors.reason
                    ? `${errorFormStyle} text-gray-500`
                    : `${defaultFormStyle} text-gray-500`
                }
                ref={register({ required: true })}
                defaultValue=""
              >
                <option value="" disabled>
                  Please select product condition
                </option>
                <option value="Parcel box unopen">Parcel box unopen</option>
                <option value="Product seal intact/unopened">
                  Product seal intact/unopened
                </option>
                <option value="Product opened">Product opened</option>
              </select>
              <div
                style={{ position: 'absolute', top: '18px', right: '8px' }}
                className={watch('reason') ? '' : 'text-gray-500'}
              >
                <i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
              </div>
              {errors.condition && (
                <p className="error">Product condition is required</p>
              )}
            </div>
          </div>
          <div className="my-8">
            {status === 3 && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 max-w-md"
                role="alert"
              >
                <strong className="font-bold">Error</strong>
                <span className="block sm:inline ml-2">
                  Submission failed due to Google reCAPTCHA cannot load
                  properly. Refresh the page and try again. If this error shows
                  again please contact support.
                </span>
              </div>
            )}
            {status === 2 && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 max-w-md">
                Your information has been submitted.
              </div>
            )}
            <input
              type="submit"
              className="btn cursor-pointer"
              value={status === 1 || status === 3 ? 'LOADING' : 'NEXT'}
              disabled={status === 1 || status === 3 ? true : false}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default withGoogleReCaptcha(ReturnClaim);
