import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../config';
import axios from 'axios';

const SelectServiceType = (props) => {
  const [enabledWalkin, setEnabledWalkin] = useState(false);
  const [enabledSendin, setEnabledSendin] = useState(false);
  useEffect(() => {
    document.title = props.title;
    const getServiceStatus = async () => {
      const result = await axios.get(`${apiUrl}/check-service-status`);
      if (result.data) {
        if (result.data.body['1'].enabled) {
          setEnabledSendin(true);
        }
        if (result.data.body['2'].enabled) {
          setEnabledWalkin(true);
        }
      }
    };
    getServiceStatus();
  }, [props.title]);
  return (
    <div>
      <h1 className="main-title py-24 text-center">Book a Repair Service</h1>
      <div className="flex justify-around lg:flex-row flex-col md:pb-16 pb-6 max-w-6xl mx-auto items-center">
        <Link to={enabledWalkin ? '/walkin-service-booking' : '#'}>
          <div
            className={
              enabledWalkin ? 'service-block active' : 'service-block disabled'
            }
          >
            <div style={{ fontSize: '3rem' }}>
              <i className="fal fa-clock"></i>
            </div>
            <h2 className="font-bold text-2xl mb-4">Walk-in Repair</h2>
            <p>Bring your handset to Service Centre for repair</p>
          </div>
        </Link>
        <Link to={enabledSendin ? '/sendin-service-booking' : '#'}>
          <div
            className={
              enabledSendin
                ? 'mt-8 lg:mt-0 service-block active'
                : 'mt-8 lg:mt-0 service-block disabled'
            }
          >
            <div style={{ fontSize: '3rem' }}>
              <i className="fal fa-box"></i>
            </div>
            <h2 className="font-bold text-2xl mb-4">Send-in Repair</h2>
            <p>Post your handset to Service Centre for repair</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SelectServiceType;
