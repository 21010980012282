import React, { useState } from 'react';
// import { Link, withRouter } from "react-router-dom";
import LogoUrl from '../static/icon_logo_Dark.png';
import disableScroll from 'disable-scroll';

const Header = ({appProps}) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleMenuBoard = () => {
        setIsOpen((isOpen) => {
            if (isOpen){
                disableScroll.off()
            } else {
                disableScroll.on()
            }
            return !isOpen
        })
        
    }

    return (
        <>
        <header className="h-12 shadow-md flex fixed top-0 inset-x-0 bg-white z-20">
            <nav className="mx-auto my-auto sm:px-6 px-0 w-full xl:max-w-6xl text-center">
                <a href="https://www.realme.com/au/" className="float-none md:float-left inline-block mt-1 md:mt-0"><img src={LogoUrl} alt="realme-logo" className="h-10" /></a>
                <div className="md:flex flex-row justify-around xl:max-w-4xl mx-auto hidden">
                    {Object.keys(appProps).map((key, index) => {
                        return (<a href={appProps[key]} target="_blank" rel="noopener noreferrer" className="px-4 py-2 header__nav__item" key={Date.now()+ index}>{key}</a>)
                    })}
                </div>
                <button className="toggle__button absolute md:hidden" onClick={toggleMenuBoard}>
                    <i className={isOpen?"fal fa-times text-3xl":"far fa-bars text-2xl"}></i>
                </button>
            </nav>
        </header>
        <div>
            <nav className={isOpen?"inset-0 block fixed z-10 bg-white":"hidden"}>
                <ul className="mt-20">
                    {Object.keys(appProps).map((key, index) => {
                        return (<li className="px-8" key={Date.now()+ index}><a href={appProps[key]} rel="noopener noreferrer" target="_blank" className="header__toggle__nav__item hover:text-primary-500 text-xl font-semibold tracking-wide">{key}</a></li>)
                    })}
                </ul>
            </nav>
        </div>
        </>
    )
}

export default Header;