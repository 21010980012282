import React, {useEffect} from "react";
import { useParams } from 'react-router-dom';
import SelectServiceCentre from './walkinForms/SelectServiceCentre';
import InputIMEI from './walkinForms/InputIMEI';
import CustomerInfo from './walkinForms/CustomerInfo';
import FaultDetail from './walkinForms/FaultDetail';
import Summary from './walkinForms/Summary';
import Confirmed from './walkinForms/Confirmed';

const WalkinServiceForm = (props) => {
    const { id } = useParams();
    const { data, setData } = props
    useEffect(() => {
        document.title = props.title
    },[props.title])
    return (
        <>
            {id==='1' && <SelectServiceCentre appProps={{data, setData}}/>}
            {id==='2' && <InputIMEI appProps={{data, setData}}/>}
            {id==='3' && <CustomerInfo appProps={{data, setData}}/>}
            {id==='4' && <FaultDetail appProps={{data, setData}}/>}
            {id==='summary' && <Summary appProps={{data, setData}}/>}
            {id==='confirmed' && <Confirmed appProps={{data, setData}}/>}
        </>
    )
}

export default WalkinServiceForm;