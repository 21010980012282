import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute';
import Home from './containers/Home';
import SelectServiceType from './containers/SelectServiceType';
import WalkinService from './containers/WalkinService';
import SendinService from './containers/SendinService';
import WalkinServiceForm from './containers/WalkinServiceForm';
import SendinServiceForm from './containers/SendinServiceForm';
import ReturnClaim from './containers/ReturnClaim';
import NotFound from './containers/NotFound';
import GuideClearPassword from './containers/GuideClearPassword';

export default function Routes({ appProps }) {
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => (
          <SelectServiceType title="Book a Repair Centre - realme Mobile Australia" />
        )}
      />
      <Route
        path="/find-service-centre"
        exact
        render={(props) => (
          <Home title="Find Service Centre - realme Mobile Australia" />
        )}
      />
      <Route
        path="/walkin-service-booking"
        exact
        render={(props) => (
          <WalkinService title="Walk-in Service Booking - realme Mobile Australia" />
        )}
      />
      <Route
        path="/sendin-service-booking"
        exact
        render={(props) => (
          <SendinService title="Send-in Service Booking - realme Mobile Australia" />
        )}
      />
      <Route
        path="/return-claim"
        exact
        render={(props) => (
          <ReturnClaim title="Send-in Service Booking - realme Mobile Australia" />
        )}
      />
      <Route
        path="/how-to-clear-lock-screen-password-in-recovery-mode"
        exact
        render={(props) => (
          <GuideClearPassword title="How to clear lock screen password in recovery mode - realme Mobile Australia" />
        )}
      />
      <AppliedRoute
        path="/walkin-service-booking/:id"
        exact
        component={WalkinServiceForm}
        appProps={{
          ...appProps,
          url: '/walkin-service-booking',
          title: 'Walk-in Service Booking - realme Mobile Australia',
        }}
      />
      <AppliedRoute
        path="/sendin-service-booking/:id"
        exact
        component={SendinServiceForm}
        appProps={{
          ...appProps,
          url: '/sendin-service-booking',
          title: 'Send-in Service Booking - realme Mobile Australia',
        }}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
