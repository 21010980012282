import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { apiUrl } from '../../config';
import axios from 'axios';

const CustomerInfo = (props) => {
  const { register, handleSubmit, errors, setValue, setError } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const defaultFormStyle =
    'selector border-gray-400 form-control md:w-96 w-64 my-4 h-10 bg-white';

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${apiUrl}/checkPostcode?suburb=${data.customer_suburb.toLowerCase()}&state=${data.customer_state.toLowerCase()}&postcode=${
          data.customer_postcode
        }`
      );
      if (result.data.code === '0') {
        setIsLoading(false);
        props.appProps.setData({
          ...props.appProps.data,
          customer_name: data.customer_name.trim(),
          customer_number: data.customer_number.trim(),
          customer_email: data.customer_email.trim(),
          customer_address_1: data.customer_address_1.trim(),
          customer_address_2:
            (data.customer_address_2 && data.customer_address_2.trim()) || '',
          customer_suburb: data.customer_suburb.trim(),
          customer_state: data.customer_state.trim(),
          customer_postcode: data.customer_postcode.trim(),
        });
        props.history.push('/sendin-service-booking/3');
      }
      if (result.data.code === '1') {
        setError('customer_postcode', 'mismatch');
        throw new Error('postcode and suburb mismatch');
      }
      throw new Error('Internal error');
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.appProps.data.customer_email) {
      const {
        customer_name,
        customer_email,
        customer_number,
        customer_address_1,
        customer_address_2,
        customer_state,
        customer_suburb,
        customer_postcode,
      } = props.appProps.data;
      setValue('customer_name', customer_name);
      setValue('customer_number', customer_number);
      setValue('customer_email', customer_email);
      setValue('customer_address_1', customer_address_1);
      setValue('customer_address_2', customer_address_2);
      setValue('customer_state', customer_state);
      setValue('customer_suburb', customer_suburb);
      setValue('customer_postcode', customer_postcode);
    }
  }, [setValue, props.appProps.data]);

  return (
    <div className="text-center">
      <h1 className="main-title pt-24 pb-8">Customer Details</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex xl:max-w-6xl mx-auto pb-6 relative flex-col items-center"
      >
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true })}
          placeholder="Name"
          name="customer_name"
          maxLength="60"
        />
        {errors.customer_name && (
          <p className="error">Customer name is required</p>
        )}
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true, pattern: /^04\d{8}$/ })}
          placeholder="Contact Number"
          name="customer_number"
          maxLength="10"
        />
        {errors.customer_number &&
          errors.customer_number.type === 'required' && (
            <p className="error">Customer contact number is required</p>
          )}
        {errors.customer_number &&
          errors.customer_number.type === 'pattern' && (
            <p className="error">
              Customer contact number should start from 04
            </p>
          )}
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
          placeholder="Email"
          name="customer_email"
        />
        {errors.customer_email && errors.customer_email.type === 'required' && (
          <p className="error">Customer email is required</p>
        )}
        {errors.customer_email && errors.customer_email.type === 'pattern' && (
          <p className="error">Invalid email</p>
        )}
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true })}
          placeholder="Address Line 1"
          name="customer_address_1"
          maxLength="40"
        />
        {errors.customer_address_1 &&
          errors.customer_address_1.type === 'required' && (
            <p className="error">Address is required</p>
          )}
        <input
          className={defaultFormStyle}
          type="text"
          ref={register()}
          placeholder="Address Line 2"
          name="customer_address_2"
          maxLength="40"
        />
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true })}
          placeholder="Suburb/Town"
          name="customer_suburb"
        />
        {errors.customer_suburb &&
          errors.customer_suburb.type === 'required' && (
            <p className="error">Suburb/Town is required</p>
          )}
        <div className="relative">
          <select
            defaultValue=""
            name="customer_state"
            className={defaultFormStyle}
            ref={register({ required: true })}
            onChange={(e) => {}}
          >
            <option value="">Please select state</option>
            <option value="ACT">ACT</option>
            <option value="NT">NT</option>
            <option value="NSW">NSW</option>
            <option value="QLD">QLD</option>
            <option value="SA">SA</option>
            <option value="TAS">TAS</option>
            <option value="WA">WA</option>
            <option value="VIC">VIC</option>
          </select>
          <div className="selector-icon">
            <i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
          </div>
        </div>
        {errors.customer_state && <p className="error">State is required</p>}
        <input
          className={defaultFormStyle}
          type="text"
          ref={register({ required: true })}
          placeholder="Postcode"
          name="customer_postcode"
        />
        {errors.customer_postcode &&
          errors.customer_postcode.type === 'required' && (
            <p className="error">Postcode is required</p>
          )}
        {errors.customer_postcode &&
          errors.customer_postcode.type === 'mismatch' && (
            <p className="error">Please check Suburb and Postcode.</p>
          )}
        <div>
          <Link
            to="/sendin-service-booking/1"
            className="btn my-8 cursor-pointer"
          >
            BACK
          </Link>
          <button
            type="submit"
            className="btn my-8 cursor-pointer ml-12 uppercase"
            disabled={isLoading}
          >
            <span>
              {isLoading && (
                <i className="fad fa-spinner fa-spin fa-spinner-third mr-2 text-md"></i>
              )}
              next
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default withRouter(CustomerInfo);
