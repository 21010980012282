import React from 'react';
const moment = require('moment');

const Confirmed = (props) => {
  const {
    customer_name,
    serviceDate,
    serviceTime,
    order,
    centreName,
    centrePhone,
    centreAddress,
  } = props.appProps.data;

  return (
    <div className="text-center xl:max-w-4xl mx-auto px-4 min-h-screen">
      <h1 className="main-title pt-24 pb-8">
        Your Walk-in Repair Service is Booked
      </h1>
      <p className="text-left text-md sm:text-lg">
        Dear {customer_name},<br />
        <br />
        Thank you for requesting walk-in repair service for your realme handset.
        <br />
        Your reference number is <span className="font-bold">{order}</span>.
        <br />
        Please bring your realme device, proof of purchase and relevant
        accessories to
        <br />
        <span className="font-bold">
          {centreName}: {centreAddress} on{' '}
          {`${moment(serviceDate).format('DD/MM/YYYY')} ${serviceTime}`}
        </span>
        <br />
        If you are experiencing difficulty in finding us, please contact{' '}
        <a
          href={`tel:${centrePhone}`}
          className="text-blue-500 hover:underline"
        >
          {centrePhone}
        </a>{' '}
        for assistance.
        <br />
        For other enquiries, please visit:
        <br />
        <a
          href="https://www.realme.com/au/support"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          https://www.realme.com/au/support
        </a>{' '}
        to get in touch with us.
        <br />
        <br />
        <br />
        Kind Regards,
        <br />
        realme Customer Support Team
        <br />
      </p>
    </div>
  );
};

export default Confirmed;
