import React, { useEffect } from 'react';
import Step1Png from '../static/clear-password-01.png';
import Step2Png from '../static/clear-password-02.png';
import Step3Png from '../static/clear-password-03.png';
import Step4Png from '../static/clear-password-04.png';
import Step5Png from '../static/clear-password-05.jpg';

const Step = ({ imgLink, stepNum, title, desc, imgLeft = false }) => (
  <div className="flex flex-wrap items-center max-w-6xl mx-auto">
    <div
      className={
        imgLeft
          ? 'md:w-1/2 px-8 mb-8 order-none'
          : 'md:w-1/2 px-8 mb-8 md:order-1'
      }
    >
      <span className="text-6xl">{stepNum}</span>
      <h3 className="text-2xl mb-3 font-heading">{title}</h3>
      <p
        className="text-gray-600 leading-relaxed"
        dangerouslySetInnerHTML={{ __html: desc }}
      ></p>
    </div>
    <div
      className={
        imgLeft ? 'md:w-1/2 px-8 mb-8' : 'md:w-1/2 px-8 md:text-right mb-8'
      }
    >
      <div className="step-img-wrapper">
        <img
          className="w-full sm:w-4/5 mx-auto"
          src={imgLink}
          alt={`stepImg${stepNum}`}
        />
      </div>
    </div>
  </div>
);

const GuideClearPassword = (props) => {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
  return (
    <section className="py-12 px-4">
      <h1 className="md:text-4xl text-center font-bold tracking-wide py-24 text-2xl">
        How to clear lock screen password in recovery mode
      </h1>
      <div>
        <Step
          imgLink={Step1Png}
          stepNum="01"
          title="Enter the recovery mode"
          desc="Turn off the phone, and then long press the volume down key and power key to enter the recovery mode. Then select language."
        />
        <Step
          imgLink={Step2Png}
          stepNum="02"
          title="Select Wipe data"
          desc={null}
          imgLeft={false}
        />
        <Step
          imgLink={Step3Png}
          stepNum="03"
          title="Enter verification code"
          desc={null}
        />
        <Step
          imgLink={Step4Png}
          stepNum="04"
          title="Format data"
          desc="Click on Format data and then confirm. All data including the lock screen password will be wiped out. And then the phone will restart."
          imgLeft={false}
        />
        <Step
          imgLink={Step5Png}
          stepNum="05"
          title="Sign in with Google Account"
          desc={`If you have logged in Google Account or turn on "Find My Device", please sign in with the original Google Account.If you can't sign in to your Google Account, please <a href="https://support.google.com/android/troubleshooter/2402620?hl=en-GB" target="_blank" class="underline hover:text-primary-500">contact Google</a >.`}
        />
      </div>
    </section>
  );
};

export default GuideClearPassword;
