import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import Modal from '../../components/Modal';

const FaultDetail = (props) => {
  const { register, errors, handleSubmit, setError, setValue } = useForm();
  const [selectedOptions] = useState(props.appProps.data.fault_option || []);
  const [modalShow, setModalShow] = useState(false);
  const toggleClass = (e) => {
    e.target.parentNode.classList.toggle('border-primary-500');
  };

  useEffect(() => {
    if (props.appProps.data.fault_description) {
      const { fault_description, fault_option } = props.appProps.data;
      fault_option.forEach((ele) => {
        setValue(ele, true);
      });
      setValue('Fault Description', fault_description);
    }
  }, [props.appProps.data, setValue]);

  const onSubmit = (data) => {
    let options = [];
    for (let [key, value] of Object.entries(data)) {
      if (value === true) {
        options.push(key);
      }
    }

    if (options.length === 0) {
      setError('select', 'empty', 'Please choose at least one fault detail');
    } else {
      props.appProps.setData({
        ...props.appProps.data,
        fault_option: options,
        fault_description: data['Fault Description'].trim(),
      });
      if (options.length === 1 && options[0] === 'Password Locked') {
        setModalShow(true);
        return;
      }
      props.history.push('/sendin-service-booking/summary');
    }
  };

  return (
    <div className="text-center">
      <h1 className="main-title pt-24 pb-8">Fault Details</h1>
      <form
        className="flex max-w-md mx-auto pb-6 relative flex-col items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-row flex-wrap justify-around pb-6">
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Cracked Screen')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Cracked Screen"
                ref={register}
              />
              <span className="text-md sm:text-lg">Cracked Screen</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Password Locked')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Password Locked"
                ref={register}
              />
              <span className="text-md sm:text-lg">Password Locked</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Physical Damage')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Physical Damage"
                ref={register}
              />
              <span className="text-md sm:text-lg">Physical Damage</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Liquid Damage')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Liquid Damage"
                ref={register}
              />
              <span className="text-md sm:text-lg">Liquid Damage</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Not Power On')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Not Power On"
                ref={register}
              />
              <span className="text-md sm:text-lg">Not Power On</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Charging')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Charging"
                ref={register}
              />
              <span className="text-md sm:text-lg">Charging</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Connectivity')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Connectivity"
                ref={register}
              />
              <span className="text-md sm:text-lg">Connectivity</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Audio')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Audio"
                ref={register}
              />
              <span className="text-md sm:text-lg">Audio</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Software')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Software"
                ref={register}
              />
              <span className="text-md sm:text-lg">Software</span>
            </label>
          </div>
          <div className="my-2 px-2 fault-item">
            <label
              className={
                selectedOptions.includes('Other')
                  ? 'fault-select py-2 px-4 w-48 border-primary-500'
                  : 'fault-select py-2 px-4 w-48'
              }
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  toggleClass(e);
                }}
                className="appearance-none hidden"
                name="Other"
                ref={register}
              />
              <span className="text-md sm:text-lg">Other</span>
            </label>
          </div>
        </div>
        {errors['select'] && (
          <p className="text-red-600 pb-6">{errors['select'].message}</p>
        )}
        <textarea
          name="Fault Description"
          ref={register({ required: true })}
          placeholder="Fault Description"
          className="w-96 sm:w-full border focus:outline-none focus:shadow-outline text-md p-2 mx-4"
        />
        {errors['Fault Description'] && (
          <p className="text-red-600 mt-2">Fault description is required</p>
        )}
        <div>
          <Link
            to="/sendin-service-booking/2"
            className="btn my-8 cursor-pointer"
          >
            BACK
          </Link>
          <input
            type="submit"
            className="btn my-8 cursor-pointer ml-12"
            value="NEXT"
          />
        </div>
      </form>
      <Modal
        isOpen={modalShow}
        setIsOpen={() => {
          setModalShow(false);
        }}
        content={() => (
          <div>
            <h1 className="text-2xl font-bold py-6">
              How to clear lock screen password in recovery mode
            </h1>
            <div className="text-left sm:px-12 px-2">
              <p>
                Available AU model: realme C3, realme 6, realme X3 SuperZoom and later
                <br />
                Software version: A.25 and above
                <br />
                Instructions: click on the guide below
              </p>
              <p className="mt-4">Please note:</p>
              <ul className="list-disc pl-5">
                <li>Make sure the software version is A.25 and above,</li>
                <li>Please note that this will wipe all data on your handset,</li>
                <li>
                  If the you have logged-in Google Account and turned on "Find My
                  Device", you should enter the password of Google account
                  after clearing the lock screen password.
                </li>
                <li>For other models, please continue the send-in booking.</li>
              </ul>
            </div>
            <div className="pt-6 flex justify-around">
              <a
                href="/how-to-clear-lock-screen-password-in-recovery-mode"
                target="_blank"
                className="hover:text-primary-500 text-2xl font-semibold"
              >
                <i className="fas fa-hand-point-right mr-2 uppercase"></i>
                Guide
              </a>
              <button
                className="hover:text-primary-500 text-2xl font-semibold"
                onClick={() => {
                  props.history.push('/sendin-service-booking/summary');
                }}
              >
                Continue anyway
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default withRouter(FaultDetail);
