import React, {useEffect} from "react";
import { useParams } from 'react-router-dom';
import InputIMEI from './sendinForms/InputIMEI';
import CustomerInfo from './sendinForms/CustomerInfo';
import FaultDetail from './sendinForms/FaultDetail';
import Summary from './sendinForms/Summary';
import Confirmed from './sendinForms/Confirmed';

const WalkinServiceForm = (props) => {
    const { id } = useParams();
    const { data, setData } = props
    useEffect(() => {
        document.title = props.title
    },[props.title])
    return (
        <>
            {id==='1' && <InputIMEI appProps={{data, setData}}/>}
            {id==='2' && <CustomerInfo appProps={{data, setData}}/>}
            {id==='3' && <FaultDetail appProps={{data, setData}}/>}
            {id==='summary' && <Summary appProps={{data, setData}}/>}
            {id==='confirmed' && <Confirmed appProps={{data, setData}}/>}
        </>
    )
}

export default WalkinServiceForm;