import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const WalkinService = (props) => {
    useEffect(() => {
        document.title = props.title
    }, [props.title])
    return (
        <div className="text-center">
            <div className="border-b xl:max-w-6xl mx-auto">
                <h1 className="main-title pt-24 pb-8">Walk-in Repair</h1>
                <p>Bring Your Handset to Service Centre for Repair</p>
                <br/>
                <Link to="/walkin-service-booking/1" className="btn my-12 uppercase">Start</Link>
            </div>
            <ul className="border-b steps flex flex-wrap max-w-6xl mx-auto my-12">
                <li className="md:w-1/4 w-1/2">
                    <div style={{fontSize:"3.8rem"}}><i className="fal fa-map-marked-alt"></i></div>
                    <p className="py-2">1. Choose Location</p>
                </li>
                <li className="md:w-1/4 w-1/2">
                    <div style={{fontSize:"3.8rem"}}><i className="fal fa-calendar-alt"></i></div>
                    <p className="py-2">2. Book A Time</p>
                </li>
                <li className="md:w-1/4 w-1/2 mt-4 md:mt-0">
                    <div style={{fontSize:"3.8rem"}}><i className="fal fa-walking"></i></div>
                    <p className="py-2">3. Visit</p>
                </li>
                <li className="md:w-1/4 w-1/2 mt-4 md:mt-0 mb-12">
                    <div style={{fontSize:"3.8rem"}}><i className="fal fa-wrench"></i></div>
                    <p className="py-2">4. Repair</p>
                </li>
            </ul>
            <div className="xl:max-w-6xl mx-auto md:pb-20 px-4 pb-10">
                <h3 className="text-2xl py-4">Kind Reminder:</h3>
                <p className="text-left">1. Please backup your personal data before coming, all data will be lost during repair.</p>
                <p className="text-left">2. Walk-in repair is available for handset only, accessories will not be accepted. Please contact us for accessory related enquiry.</p>
            </div>
        </div>
    )
}

export default WalkinService;
