import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../../config';
import moment from 'moment';

const SelectServiceCentre = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
  } = useForm();
  const [stateArray, setStateArray] = useState([]);
  const [centreArray, setCentreArray] = useState([]);
  const [excludeDate, setExcludeDate] = useState([]);
  const [selectedDate, setselectedDate] = useState(null);
  const [TimeArray, setTimeArray] = useState([]);
  const defaultFormStyle =
    'selector border-gray-400 form-control md:w-96 w-64 my-4 h-10 bg-white cursor-pointer';
  const disableFormStyle =
    'selector border-gray-400 form-control md:w-96 w-64 my-4 h-10 cursor-not-allowed bg-gray-200';

  const handleChange = async (date) => {
    setValue('date', date);
    setselectedDate(date);
    setValue('time', '');
    try {
      const result = await axios.get(
        `${apiUrl}/getCentreOpenHour?centreId=${
          getValues()['centre']
        }&date=${moment(date).format('YYYY-MM-DD')}`
      );
      if (result.data.code === '0') {
        setTimeArray(result.data.body);
      } else {
        throw result.data.msg;
      }
    } catch (error) {
      setTimeArray([]);
      console.log(error);
    }
  };

  const loadState = async () => {
    try {
      const result = await axios.get(`${apiUrl}/getState`);
      if (result.data.code === '0') {
        return result.data.body;
      } else {
        throw result.data.msg;
      }
    } catch (error) {
      return new Error(error);
    }
  };

  const onChangeState = async (state_id) => {
    setselectedDate(null);
    setValue('centre', '');
    setValue('date', '');
    setValue('time', '');
    try {
      const result = await axios.get(
        `${apiUrl}/getCentreLocation?stateId=${state_id}`
      );
      if (result.data.code === '0') {
        setCentreArray(result.data.body);
      } else {
        throw result.data.msg;
      }
    } catch (error) {
      setCentreArray([]);
      return new Error(error);
    }
  };

  const onSelectCentre = async (centre_id) => {
    setselectedDate(null);
    setValue('date', '');
    setValue('time', '');
    try {
      const result = await axios.get(
        `${apiUrl}/getCentreRestDate?centreId=${centre_id}`
      );
      if (result.data.code === '0') {
        const arr = [Date.now()];
        result.data.body.forEach((ele) => {
          arr.push(new Date(ele));
        });
        setExcludeDate(arr);
      } else {
        throw result.data.msg;
      }
    } catch (error) {
      setExcludeDate([Date.now()]);
      console.log(error);
    }
  };

  const onSubmit = (data) => {
    props.appProps.setData({
      ...props.appProps.data,
      state: data.state,
      stateName: stateArray.filter((ele) => ele.id + '' === data.state)[0].name,
      centre: data.centre,
      centreName: centreArray.filter((ele) => ele.id + '' === data.centre)[0]
        .name,
      serviceDate: data.date,
      serviceTime: data.time,
      finishPage: 1,
    });
    props.history.push('/walkin-service-booking/2');
  };

  // const DateInput = ({ value, onClick }) => (
  //     <button className={default}>
  //         {value}
  //     </button>
  // )

  useEffect(() => {
    loadState().then((data) => {
      if (Array.isArray(data)) {
        setStateArray(data);
      }
    });
    register({ name: 'date', value: '' }, { required: true });
  }, [register]);

  return (
    <div className="text-center">
      <h1 className="main-title pt-24 pb-8">Select Service Centre</h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center xl:max-w-6xl border-b mx-auto"
      >
        <div className="relative">
          <select
            defaultValue=""
            name="state"
            className={defaultFormStyle}
            ref={register({ required: true })}
            onChange={(e) => {
              onChangeState(e.target.value);
            }}
          >
            <option value="">Please select state</option>
            {stateArray &&
              stateArray.map((ele) => (
                <option value={ele.id} key={`${ele.id}${ele.name}`}>
                  {ele.name}
                </option>
              ))}

            {/* <option value="VIC">VIC</option> */}
          </select>
          <div className="selector-icon">
            <i className="far fa-chevron-down fill-current text-lg pr-2 pt-1"></i>
          </div>
        </div>
        {errors.state && <p className="error">State is required</p>}
        <div className="relative">
          <select
            defaultValue=""
            name="centre"
            className={
              centreArray.length > 0 ? defaultFormStyle : disableFormStyle
            }
            ref={register({ required: true })}
            disabled={centreArray.length === 0}
            onChange={(e) => {
              onSelectCentre(e.target.value);
            }}
          >
            <option value="">Please select centre</option>
            {centreArray.map((ele) => (
              <option value={ele.id} key={`${ele.id}${ele.name}`}>
                {ele.name}
              </option>
            ))}
          </select>
          <div className="selector-icon">
            <i
              className={
                watch('state')
                  ? 'far fa-chevron-down fill-current text-lg pr-2 pt-1'
                  : 'fal fa-lock-alt fill-current text-lg pr-2 pt-1'
              }
            ></i>
          </div>
        </div>
        {errors.centre && <p className="error">Service centre is required</p>}
        <div className="relative">
          <DatePicker
            className={watch('centre') ? defaultFormStyle : disableFormStyle}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            maxDate={new Date(moment().add(14, 'day'))}
            showPopperArrow={false}
            excludeDates={excludeDate}
            selected={selectedDate}
            onChange={handleChange}
            placeholderText="Please select date"
            disabled={!watch('centre')}
            withPortal
            fixedHeight
          />
          <div className="selector-icon">
            <i
              className={
                watch('centre')
                  ? 'far fa-chevron-down fill-current text-lg pr-2 pt-1'
                  : 'fal fa-lock-alt fill-current text-lg pr-2 pt-1'
              }
            ></i>
          </div>
        </div>
        {errors.date && errors.date.ref.value === '' && (
          <p className="error">Date is required</p>
        )}
        <div className="relative">
          <select
            defaultValue=""
            name="time"
            className={watch('date') ? defaultFormStyle : disableFormStyle}
            ref={register({ required: true })}
            disabled={!watch('date')}
          >
            <option value="">Please select time</option>
            {TimeArray.map((ele, index) => (
              <option key={index} value={ele}>
                {ele}
              </option>
            ))}
          </select>
          <div className="selector-icon">
            <i
              className={
                watch('date')
                  ? 'far fa-chevron-down fill-current text-lg pr-2 pt-1'
                  : 'fal fa-lock-alt fill-current text-lg pr-2 pt-1'
              }
            ></i>
          </div>
        </div>
        {errors.time && <p className="error">Time is required</p>}
        <input type="submit" className="btn my-8 cursor-pointer" value="NEXT" />
      </form>
      <div className="xl:max-w-6xl mx-auto md:pb-20 px-4 pb-10">
        <h3 className="text-2xl py-8">Kind Reminder:</h3>
        <p className="text-center">
          If there is no Service Centre near you, we recommend you using our
        </p>
        <p className="text-center my-4">
          <Link
            to="/sendin-service-booking"
            className="text-blue-500 hover:text-primary-500"
          >
            Send-in Service
          </Link>
        </p>
      </div>
    </div>
  );
};

export default withRouter(SelectServiceCentre);
