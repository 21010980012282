import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="relative h-screen">
            <div className="text-center not-found flex flex-col">
                <i className="fal fa-frown" style={{fontSize:'8rem'}}></i>
                <h1 className="font-bold tracking-wider" style={{fontSize:'2.7rem'}}>Page not found</h1>
                <p className="font-semibold">The page you are looking for doesn't exist or an other error occurred.<br/>Go back, or head over to <Link to="/" className="text-blue-500 hover:underline">https://repair.realme.net.au/</Link> to choose a new direction.</p>
            </div>
        </div>
    )
}

export default NotFound;